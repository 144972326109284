import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../../components/Layout';
import { LightgalleryItem } from "react-lightgallery";
import img1_1 from '../../assets/images/bemutatoterem/1_1.jpeg'
import img1_2 from '../../assets/images/bemutatoterem/1_2.jpeg'
import img1_3 from '../../assets/images/bemutatoterem/1_3.jpeg'
import img1_4 from '../../assets/images/bemutatoterem/1_4.jpeg'
import img2_1 from '../../assets/images/bemutatoterem/2_1.jpeg'
import img2_2 from '../../assets/images/bemutatoterem/2_2.jpeg'
import img2_3 from '../../assets/images/bemutatoterem/2_3.jpeg'
import img2_4 from '../../assets/images/bemutatoterem/2_4.jpeg'

const BemutatoteremBudapest = () => (
  <Layout page="bemutatoterem-budapest">
    <article id="main">
      <header>
        <h2>BEMUTATÓTEREM - BUDAPEST</h2>
        <p>
        Bemutatótermünk szemetgyönyörködtető boralkotásokkal nyűgözi le a Smart Hotel vendégeit.
        </p>
      </header>
    </article>

    <section id="cta" className="wrapper style5 special bottom-line">
      <div className="inner">
        <header className="major large-header">
          <h2>
          A HOTELRŐL
          </h2>
          <p>
          A Smart Hotel Budapest****, Magyarország első okos bor tematikájú hotele. 
          Budapest város szívében, a folyton lüktető Király utcától 10 méterre, a budapesti múzeum negyedtől 800 méterre 
          (Hősök Tere, Fővárosi Állat- és Növénykert, Széchenyi Fürdő) helyezkedik el.
          4 csillagos hotelünk, 14 industrial designer szobával, beépített okos eszközökkel felszerelt, 
          innovatív elemekkel színesített boutique hotel. 
          </p>

          <p>
          A hozzánk látogatók teljes kikapcsolódásáról wellness központunk: finn szauna, gőzfürdő, tepidárium gondoskodik.
          A szálloda szezononként megújuló ajánlatokkal és aktuális akciókkal készül a hozzánk látogatók színvonalas fogadására.
          Barátságos és nyugodt környezetet biztosít minden generáció számára, legyen szó városlátogatásról, 
          szabadidős, vagy üzleti programokról, tradicionális magyar, köztük a Juhász Testvérek borainak kóstolásáról.
          Tapasztalja meg a Smart élményt! 
          </p>
        </header>
      </div>
    </section>

    <section id="cta" className="wrapper style5">
			<div className="inner">
        <section>
          <div className="box alt">
            <div className="row gtr-50 gtr-uniform">
              <div className="col-12">
                <span className="image fit gallery-thumbnail" >
                  <LightgalleryItem src={img1_1}><StaticImage  src="../../assets/images/bemutatoterem/1_1.jpeg" alt="" /></LightgalleryItem>
                </span>
              </div>
              
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_2}><StaticImage   src="../../assets/images/bemutatoterem/1_2.jpeg" alt="" /></LightgalleryItem>
                  </span>
              </div>
              
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_3}><StaticImage   src="../../assets/images/bemutatoterem/1_3.jpeg" alt="" /></LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_4}><StaticImage   src="../../assets/images/bemutatoterem/1_4.jpeg" alt="" /></LightgalleryItem>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>


    <section id="cta" className="wrapper style5 special bottom-line">
      <div className="inner">
        <header className="major large-header">
          <h2>
          A BEMUTATÓTEREMRŐL
          </h2>
          <p>
          Szenvedélyünk az étel, Szerelmünk a bor.
          Bemutatótermünk szemetgyönyörködtető boralkotásokkal nyűgözi le a Smart Hotel vendégeit. 
          Kezdetektől fogva a Vendégeink elégedettségének elérése és igényeik kielégítése a legmagasabb
          szinteken a célunk az, hogy megteremtsük a legszerethetőbb környezetet 
          legyen szó üzleti, szabadidős vagy családi utazásról.
          </p>
          <p>
          „Nem egy klasszikus borbárként fogunk üzemelni, nem lehet majd ide az utcáról besétálni, 
          itt az exkluzív és felelősségteljes borfogyasztás lesz a fókuszban. Előre kell majd asztalt foglalni minimum hat fővel, 
          cserébe viszont teljeskörű kulináris kényeztetésben lesz részük a vendégeknek. 
          A személyzet összes tagja többször járt a pincészetünkben, kóstolták és ismerik a borainkat, 
          így hitelesen és szakszerűen tudják azokat prezentálni, miközben a falon tv-n fut a rólunk szóló film. 
          A konyha alapesetben fingerfoodokkal készül, ám ha a későbbiekben azt látjuk, 
          van rá igény, akkor melegkonyha is folyamatosan fog üzemelni.” 
          <br style={{display: 'block'}} />
          - Juhász Ádám a borbár megnyitója kapcsán (vinoport.hu)

          </p>
          {/* <p>
          A teljes interjú itt!  
          </p> */}
        </header>
      </div>
    </section>

    <section id="cta" className="wrapper style5">
			<div className="inner">
        <section>
          <div className="box alt">
            <div className="row gtr-50 gtr-uniform">
              <div className="col-12">
                <span className="image fit gallery-thumbnail" >
                  <LightgalleryItem src={img2_1}><StaticImage  src="../../assets/images/bemutatoterem/2_1.jpeg" alt="" /></LightgalleryItem>
                </span>
              </div>
              
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_2}><StaticImage   src="../../assets/images/bemutatoterem/2_2.jpeg" alt="" /></LightgalleryItem>
                  </span>
              </div>
              
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_3}><StaticImage   src="../../assets/images/bemutatoterem/2_3.jpeg" alt="" /></LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_4}><StaticImage   src="../../assets/images/bemutatoterem/2_4.jpeg" alt="" /></LightgalleryItem>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    
  </Layout>
);

export default BemutatoteremBudapest;
